import {
  Box,
  Drawer,
  List,
  ListItemButton,
  styled,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import ScrollBar from "simplebar-react";
import topMenuList from "./topMenuList";
import MenuIcon from '@mui/icons-material/Menu';

// root component interface
interface SideNavBarProps {
  showMobileSideBar: boolean;
  closeMobileSideBar: () => void;
}

// custom styled components
const MainMenu = styled(Box)(({ theme }) => ({
  left: 0,
  width: "auto",
  height: "100%",
  position: "fixed",
  boxShadow: theme.shadows[2],
  transition: "all 0.3s ease",
  zIndex: theme.zIndex.drawer + 11,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down("md")]: { left: -80 },
  "& .simplebar-track.simplebar-vertical": { width: 7 },
  "& .simplebar-scrollbar:before": {
    background: theme.palette.text.primary,
  },
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  marginBottom: "1rem",
  justifyContent: "flex-start",
  "&:hover": { backgroundColor: "transparent" },
}));

// root component
const DashboardSideBar: FC<SideNavBarProps> = ({
  showMobileSideBar,
  closeMobileSideBar,
}) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false); // Track sidebar state
  const [active, setActive] = useState("Dashboard");
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const handleActiveMainMenu = (menuItem: any) => () => {
    setActive(menuItem.title);
    navigate(menuItem.path);
    closeMobileSideBar();
  };

  const toggleSidebar = () => setExpanded((prev) => !prev);

  // main menus content
  const mainSideBarContent = (
    <List sx={{ height: "100%" }}>
      <StyledListItemButton sx={{ justifyContent: "center" }} disableRipple>

        <img src="/assets/images/logo.png" alt="Logo" width={50} onClick={() => toggleSidebar()} />
      </StyledListItemButton>

      <ScrollBar style={{ maxHeight: "calc(100% - 50px)" }}>
        {topMenuList.map((nav, index) => (
          <Tooltip title={nav.title} placement="right" key={index} disableHoverListener={expanded}>
            <StyledListItemButton
              disableRipple
              onClick={handleActiveMainMenu(nav)}
              sx={{ justifyContent: expanded ? "flex-start" : "center" }}
            >
              <nav.Icon
                sx={{
                  color: active === nav.title ? "primary.main" : "secondary.400",
                }}
              />
              {expanded && (
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {nav.title}
                </Typography>
              )}
            </StyledListItemButton>
          </Tooltip>
        ))}
      </ScrollBar>
    </List>
  );

  // for mobile device
  if (downMd) {
    return (
      <Drawer
        anchor="left"
        open={showMobileSideBar}
        onClose={closeMobileSideBar}
        PaperProps={{ sx: { width: expanded ? 200 : 80 } }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            width: "inherit",
            position: "fixed",
            overflow: "hidden",
            flexDirection: "column",
            boxShadow: (theme) => theme.shadows[1],
            backgroundColor: (theme) => theme.palette.background.paper,
            "& .simplebar-track.simplebar-vertical": { width: 7 },
            "& .simplebar-scrollbar:before": {
              background: (theme) => theme.palette.text.primary,
            },
          }}
        >
          {mainSideBarContent}
        </Box>
      </Drawer>
    );
  }

  return (
    <MainMenu sx={{ width: expanded ? 200 : 80 }}>
      {mainSideBarContent}
    </MainMenu>
  );
};

export default DashboardSideBar;