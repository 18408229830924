import { HttpMethod } from '../utils/network';
import { API } from '../utils/request';

// Define interfaces for request payloads
interface LoginRequest {
  email: string;
  password: string;
}

interface RegisterRequest{
  email: string,
  password: string,
  role: string
}

interface ProfileRequest {
  authToken: string;
}

interface PasswordResetRequest {
  email: string;
}

interface ResetPasswordRequest {
  newPassword: string;
  token: string;
}

// Login API call
export const Login = (loginRequest: LoginRequest) => {
  const urlPath = '/user/login';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: loginRequest
  });
};

export const Register = (registerRequest: RegisterRequest) => {
  const urlPath = '/user/register';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: registerRequest
  });
};


// Profile API call
export const Profile = (profileRequest: ProfileRequest) => {
  const urlPath = '/user/profile';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: profileRequest
  });
};

// Send password reset mail API call
export const forgotPassword = (passwordResetRequest: PasswordResetRequest) => {
  const urlPath = '/user/forgot_password';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: passwordResetRequest
  });
};

// Reset password API call
export const resetPassword = (passwordResetRequest: ResetPasswordRequest) => {
  const urlPath = '/user/reset_password';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: passwordResetRequest
  });
};