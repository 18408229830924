import Icons from "icons/sidebar";

const index = [
  {
    title: "Dashboard",
    Icon: Icons.DashboardIcon,
    path: "/dashboard",
  },
  
  {
    title: "Accounts",
    Icon: Icons.UserProfileIcon,
    path: "/dashboard/accounts",
  },
  {
    title: "Job",
    Icon: Icons.UserManagementIcon,
    path: "/dashboard/jobs",
  },
  {
    title: "Emails",
    Icon: Icons.UserManagementIcon,
    path: "/dashboard/emails",
  },
  {
    title: "Review",
    Icon: Icons.UserManagementIcon,
    path: "/dashboard/review",
  },
];

export default index;
