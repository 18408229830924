import { Backdrop, Box, CircularProgress } from "@mui/material";
import NProgress from "nprogress";
import { useEffect } from "react";

const LoadingScreen = () => {
  NProgress.configure({
    showSpinner: false,
    // easing: "ease",
    // speed: 700,
    // minimum: 0.1,
  });

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <>
      <Backdrop open={true} sx={{ zIndex: 1, color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default LoadingScreen;
