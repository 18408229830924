import { CssBaseline, IconButton, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { FC, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { ukoTheme } from "./theme";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

const App: FC = () => {
  const allPages = useRoutes(routes);
  const [themeMode, setThemeMode] = useState<"light" | "dark" | undefined>('light');

  // App theme
  const appTheme = ukoTheme(themeMode);

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };
  // toaster options
  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif",
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <Toaster toastOptions={toasterOptions} />
        {/* Toggle Button */}
        <IconButton
          color="inherit"
          onClick={toggleTheme}
          sx={{ position: "absolute", top: 16, right: 16 }}
        >
          {themeMode === "light" ? <Brightness4Icon /> : <Brightness7Icon />}
        </IconButton>
        {allPages}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
