import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getBaseUrl } from './network';

// Define the types for request options
interface RequestOptions {
  method: AxiosRequestConfig['method'];
  urlPath: string;
  body?: any;
  additionalHeader?: Record<string, string>;
  params?: AxiosRequestConfig['params'];
}

interface RequestOptionsWithoutHeaders {
  method: AxiosRequestConfig['method'];
  urlPath: string;
  body?: any;
}

// Convert API class to TypeScript with type safety
export class API {
  
  // Method with headers
  static request = async ({
    method,
    urlPath,
    body,
    params, // Add params to the function signature
    additionalHeader
  }: RequestOptions): Promise<AxiosResponse<any>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: method,
          baseURL: await getBaseUrl(),
          headers: {
            ...additionalHeader
          },
          url: urlPath,
          data: body,
          params: params // Add params to the request configuration
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };

  // Method without headers
  static requestWithoutHeaders = async ({ method, urlPath, body }: RequestOptionsWithoutHeaders): Promise<AxiosResponse<any>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: method,
          baseURL: await getBaseUrl(),
          url: urlPath,
          data: body
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}